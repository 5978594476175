<template>
  <div  id="thoughtGraphPane" ref="thoughtGraphPane">
    <button @click="clearThoughtMap" class="clear-thought-map">
      Clear Map
    </button>
    <vue-loaders-ball-beat v-if="isLoading"></vue-loaders-ball-beat>
    <div id="mountNode" ref="mountNode"></div>
  </div>
</template>

<script>
import G6 from '@antv/g6';
// import axios from 'axios';
import gocGraphConfig from '@/config/thoughtMapConfig';
// import DendroGramComp from './DendroGramComp.vue';

export default {
  components: {
    // DendroGramComp,
  },
  data() {
    return {
      isLoading: false,
      toggleGraph: true,
      searchWord: '',
      formDataObj: {
        id: '',
        label: 'Node Label',
        description: 'Node Description',
        tags: 'Node Tags',
        type: 'circle',
        size: '20',
        color: '#FFFFFF',
      },
    };
  },

  computed: {
    g6_data() {
      return this.$store.state.nav_data;
    },
    edit_mode() {
      return this.$store.state.gmodule.edit_mode;
    },
  },

  watch: {
    g6_data(val) {
      this.g6_graph.changeData(val);
      this.g6_graph.refresh();
      if (this.$store.state.current_thought) {
        this.currentSelectedStateUpdate();
      }
    },
    edit_mode(val) {
      if (val === 'hsl') {
        this.g6_graph.setMode('hsl');
      } else {
        this.g6_graph.setMode('default');
      }
    },
  },

  async mounted() {
    await this.navGraphBuild();
    new ResizeObserver(this.resizeLayout).observe(this.$refs.thoughtGraphPane);
  },

  async created() {
    // await this.$highway.singleDispatcher('wikiGraphAction').then(() => {
    //   this.g6_graph.changeData(this.$store.state.g6_graph_data);
    // });
  },

  methods: {
    resizeLayout() {
      this.g6_graph.changeSize(this.$refs.thoughtGraphPane.scrollWidth, this.$refs.thoughtGraphPane.scrollHeight);
    },
    updateMyNode(val) {
      const updatedNode = this.g6_graph.findById(val.id);
      updatedNode.update(val);
    },
    clearThoughtMap() {
      this.$store.dispatch('clearThoughtMap');
    },
    specialNodeStyles(node) {
      if (node.tjtype === 'entity' && this.$store.state.current_thought) {
        if (node.label === this.$store.state.current_thought.label) {
          return {
            type: 'rect',
            style: {
              fill: 'blue',
            },
          };
        }
        return {
          type: 'modelRect',
        };
      }

      return {};
    },

    async navGraphBuild() {
      // plugins
      const tooltip = new G6.Tooltip({
        offsetX: 10,
        offsetY: 10,
        fixToNode: [1, 0.5],
        // the types of items that allow the tooltip show up
        // 允许出现 tooltip 的 item 类型
        itemTypes: ['node', 'edge'],
        // custom the tooltip's content
        // 自定义 tooltip 内容
        getContent: (e) => {
          const outDiv = document.createElement('div');
          outDiv.style.width = 'fit-content';
          outDiv.style.height = 'fit-content';
          const model = e.item.getModel();
          if (e.item.getType() === 'node') {
            outDiv.innerHTML = `${model.label}`;
          } else {
            const source = e.item.getSource();
            const target = e.item.getTarget();
            outDiv.innerHTML = `source: ${
              source.getModel().label
            }<br/>target: ${target.getModel().label}`;
          }
          return outDiv;
        },
      });
      // this.gdata = this.$store.state.g6_graph_data;
      const config = gocGraphConfig;
      config.plugins = [tooltip];
      config.container = this.$refs.mountNode;
      config.width = this.$refs.thoughtGraphPane.scrollWidth;
      config.height = this.$refs.thoughtGraphPane.scrollHeight;
      this.g6_graph = new G6.Graph(config);

      // Mouse enter State
      // this.g6_graph.on('node:mouseenter', (e) => {
      //   const nodeItem = e.item;
      //   // this.g6_graph.setItemState(nodeItem, 'hover', true);
      //   // eslint-disable-next-line

      // });
      // // Mouse leave State:
      // this.g6_graph.on('node:mouseleave', (e) => {
      //   const nodeItem = e.item;
      //   this.g6_graph.setItemState(nodeItem, 'hover', false);
      //   // eslint-disable-next-line

      // });

      // Click Event
      this.g6_graph.on('node:click', (e) => {
        const nodeItem = e.item;

        if (this.$store.state.gmodule.edit_mode === 'explore') {
          // eslint-disable-next-line
          const nodeId = e.item._cfg.id;

          const clickNodes = this.g6_graph.findAllByState('node', 'click');
          clickNodes.forEach((cn) => {
            this.g6_graph.setItemState(cn, 'click', false);
          });

          this.g6_graph.setItemState(nodeItem, 'click', true);
          // const payload = { word: nodeId, type: 'entity', lang: this.$store.state.locale.currentLocale };
          // eslint-disable-next-line
        } else if (this.$store.state.gmodule.edit_mode === "remove") {
          this.g6_graph.removeItem(nodeItem);
        } else if (this.$store.state.gmodule.edit_mode === 'hide') {
          nodeItem.hide();
        } else if (this.$store.state.gmodule.edit_mode === 'lock') {
          nodeItem.lock();
        } else if (this.$store.state.gmodule.edit_modedit_mode === 'hsl') {
          this.g6_graph.setMode('hsl');
        } else if (this.$store.state.gmodule.edit_mode === 'default') {
          this.g6_graph.setMode('default');
        } else if (this.$store.state.gmodule.edit_mode === 'looked_at') {
          // eslint-disable-next-line
          const model = nodeItem._cfg.model;

          this.formDataObj = model;
          const btn = document.getElementById('nodeOperationWikiBtn');
          btn.click();
        }
      });
      this.g6_graph.data(this.$store.state.nav_data);
      // this.g6_graph.node((node) => this.specialNodeStyles(node));
      this.g6_graph.render();

      // Temp Fix
      setTimeout(() => {
        this.g6_graph.changeData(this.$store.state.nav_data);
        this.g6_graph.refresh();
        if (this.$store.state.current_thought) {
          this.currentSelectedStateUpdate();
        }
      });
    },
    currentSelectedStateUpdate() {
      this.g6_graph.getNodes().forEach((node) => {
        this.g6_graph.clearItemStates(node);

        if (node.getModel().label === this.$store.state.current_thought.label) {
          this.g6_graph.setItemState(node, 'current_ent', true);
        }
      });
    },
  },
};
</script>

<style scoped>
div {
  font-family: "Helvetica", sans-serif;
}

.clear-thought-map {
  position: absolute;
  right: 1rem;
  top: 1rem;
  border-radius: 0.3rem;
  color: var(--gray);
  border: none;
  cursor: pointer;
}
</style>
