export default {
  container: 'mountNode', // The container id or HTML node of the graph canvas. | kaaro: To be replaced by Graph Container Ref
  width: 1500,
  height: 650,
  // animate: true, // Boolean, whether to activate the animation when global changes happen
  // animateCfg: {
  //   duration: 100, // Number, the duration of one animation
  //   easing: 'linearEasing', // String, the easing function
  // },
  layout: {
    type: 'gForce',
    center: [800, 400], // The center of the graph by default
    linkDistance: 100,
    nodeStrength: 1000,
    edgeStrength: 300,
    nodeSize: 30,
    preventOverlap: true,
  },
  enabledStack: true,
  defaultCombo: {
    type: 'circle',
    /* The minimum size of the combo. combo 最小大小 */
    /* style for the keyShape */
    style: {
      lineWidth: 1,
      opacity: 0.1,
      fill: '#010148',
    },
    labelCfg: {
      position: 'top',
      color: '#fff',
      style: {
        fontSize: 15,
        fill: '#fff',
        fontWeight: 500,
        fontFamily: 'Poppins',
        color: '#fff',
      },
    },
  },

  modes: {
    default: [
      'drag-node',
      'drag-canvas',
      'zoom-canvas',
      'click-add-node',
      'drag-combo',
      'collapse-expand-combo',
      {
        type: 'lasso-select',
        delegateStyle: {
          fill: '#fdc400',
          fillOpacity: 0.05,
          stroke: '#fdc400',
          lineWidth: 1,
        },
        onSelect: () => {},
        trigger: 'shift',
      },
    ],
    defaultMain: [
      'drag-node',
      'drag-canvas',
      'zoom-canvas',
      'click-add-node',
      'drag-combo',
      'collapse-expand-combo',

      {
        type: 'lasso-select',
        delegateStyle: {
          fill: '#fdc400',
          fillOpacity: 0.05,
          stroke: '#fdc400',
          lineWidth: 1,
        },
        onSelect: () => {},
        trigger: 'shift',
      },
    ],
    hsl: ['activate-relations', 'activate-node', 'activate-edge', 'click-select', 'drag-node', 'drag-canvas', 'zoom-canvas'],
  },
  nodeStateStyles: {
    searchEd: {
      size: [200],
      stroke: '#fff',
      fill: '#0fa',
      lineWidth: 1,
      shadowColor: '#0fa',
      shadowBlur: 10,
    },

    opacityO: {
      opacity: 0.1,
    },

    searchRelatedEd: {
      stroke: '#fff',
      fill: '#0fa',
      lineWidth: 1,
      shadowColor: '#0fa',
      shadowBlur: 10,
    },

    focusEd: {
      stroke: '#fff',
      fill: '#010148',
      opacity: 1,
      lineWidth: 1,
      shadowColor: '#fff',
      shadowBlur: 1,
    },
    relatedEd: {
      stroke: '#010148',
      fill: '#fce181',
      opacity: 1,
      lineWidth: 1,
      shadowColor: '#010148',
      shadowBlur: 1,
    },
    current_ent: {
      stroke: '#fdc400',
      fill: '#010148',
      lineWidth: 1,
      shadowColor: '#fdc400',
      shadowBlur: 2,
    },
  },

  edgeStateStyles: {
    focusEd: {
      stroke: '#fff',
      fill: '#fce181',
      opacity: 0.6,
      lineWidth: 1,
      shadowColor: '#fce181',
      shadowBlur: 1,
    },
    relatedEd: {
      stroke: '#fff',
      fill: '#fff',
      opacity: 1,
      lineWidth: 1,
      shadowColor: '#fff',
      shadowBlur: 1,
    },

    opacityO: {
      opacity: 0.1,
    },
  },

  comboStateStyles: {
    focusEd: {
      stroke: '#fff',
      fill: '#333',
      opacity: 0.6,
      lineWidth: 1,
      shadowColor: '#333',
      shadowBlur: 1,
    },
  },

  defaultNode: {
    // size: [20],
    style: {
      stroke: '#010148',
      lineWidth: 1,
    },
    labelCfg: {
      position: 'top',
      color: '#fff',
      style: {
        fontSize: 15,
        fill: '#fff',
        fontWeight: 500,
        fontFamily: 'Poppins',
        color: '#fff',
      },
    },
  },
  defaultEdge: {
    labelCfg: {
      position: 'top',
      color: '#fff',
      style: {
        fontSize: 15,
        fill: '#fff',
        fontWeight: 500,
        fontFamily: 'Poppins',
        color: '#fff',
      },
    },
  },
};
